.navigation__box {
  transition: all 1s;
}

.navigation__box.close {
  width: 30px;
}

.navigation__box.open {
  width: 115px;
}

.navigation__mobile {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.navigation__arrow {
  top: 5px;
  left: 5px;
}

.nav__box__circle {
  background-color: white;
  padding: 18px 20px;
  border-radius: 0%;
}

svg {
  transition: all 300ms;
}

.close .navigation__arrow svg {
  transform: rotate(0deg);
}

.open .navigation__arrow svg {
  transform: rotate(180deg);
}

.close .navigation__button {
  display: none;
}
