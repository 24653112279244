.upl-img {
  margin-bottom: unset !important;
}

/* styles.css */
.custom-modal {
}

.custom-modal-wrap {
  backdrop-filter: blur(5px);
}

