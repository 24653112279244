/* .col__item {
  height: 100%;
} */
.bg_item {
  font-weight: 700;
  font-size: 14px;
  color: white;
}

.title__category__mobile {
  font-weight: 800;
  font-size: 27px;
  margin-bottom: unset;
}
.mobile .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0px 7px;
}