.home__menu__item {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.home__outline {
  border-radius: 0px;
  border: 2px solid #ffffff;
}

.ant-card-bordered,
.ant-card-hoverable:hover {
  border: 4px solid white;
}

.menu__title {
  bottom: 10px;
  left: 30px;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
}

.upload__wrapper {
  background: transparent;
}

.video-dotline {
  position: relative;
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
}

.video-dotline .text {
  font-weight: 700;
  font-size: 12px;
  color: #e84a24;
}

.upl-img span {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.upl-img img {
  width: 48px;
}

.video-dotline-err {
  position: relative;
  border: 1px dashed red;
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 150px;
}

.video-upload {
  opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 1px solid red;
}

.video-upload:hover {
  cursor: pointer;
}

.video-preview {
  height: 220px;
  max-width: 100%;
}

.upl-img {
  margin-bottom: 10px;
}

.change__attachment {
  transition: all 0.3s ease-in-out;
}

.change__attachment.bg-blue-500 path {
  fill: white;
}

.input_notes {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.005em;
  color: #ff6e72;
}

.progress__bar {
  left: 0;
  right: 0;
  margin: auto;
}

.progress__move {
  width: 0;
  transition: 3s;
  left: 0;
  margin: unset;
}

.image__room {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
}

@media screen and (max-width: 425px) {
  .home__logo {
    width: 100px;
  }
}
