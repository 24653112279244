#experiment {
  background-color: #212120;
}

.pb-200 {
  margin-bottom: 200px;
}

.desktop__view {
  display: block;
}

.ipad__view {
  display: none;
}

.opacity-0 {
  opacity: 0;
}

.h-auto {
  height: auto !important;
}

/* Place iPad 3 Styles Here */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .desktop__view {
    display: none;
  }

  .ipad__view {
    display: block;
  }
}

/* @media screen and (min-width: 2054px) { */
/* For portrait layouts only */

/* .desktop__view {
    display: block;
  }

  .ipad__view {
    display: none;
  }
} */

#id__layout__desktop__download.experiment_layout {
  height: 100%;
  z-index: -12;
}

.footer__experiment {
  bottom: 0;
  left: 0;
  width: 100%;
  /* position: absolute; */
  z-index: 4;
}

#experiment .btn__primary,
#experiment .btn__primary:focus {
  background-color: white;
  border: 1px solid white;
  color: #212120;
  font-weight: bold;
}

#experiment .btn__primary:hover {
  background-color: #eeeeeec5;
  border: 1px solid #eeeeeec5;
}

.footer__experiment h6 {
  text-shadow: 0 0 3px black;
}

.alice-carousel__dots,
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: none !important;
}

.title__card {
  background-color: #4d4d4d;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 10px 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.footer__experiment .ant-card-bordered {
  border: none;
}

#experiment .alice-carousel__stage-item {
  width: fit-content !important;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

#experiment .alice-carousel__stage {
  text-align: left;
}

.experiment__button {
  top: 20px;
}

.carousel__card {
  height: 120px;
  width: 120px;
}

.canvas__field,
.target__image__experiment {
  height: 100%;
  position: absolute;
  top: 7px;
  right: 0;
  left: 0;
  bottom: 0;
  /* margin: auto; */
}

.m-auto {
  margin: auto !important;
}

.rotated_floor {
  background-color: #4d4d4d;
}

.floor__image {
  width: 100%;
  aspect-ratio: 1;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0px;
}

.carousel__card.active {
  border-radius: 0px !important;
  border: 3px solid #d15a25 !important;
}

.experiment_layout {
  height: calc(100% - 229px);
}
/* #layout__desktop__download.layout__ipad {
} */

.experiment_layout.share {
  height: calc(100% - 94px);
}

.select__pattern {
  width: 230px;
  bottom: 15.5rem;
  right: 1.5rem;
  z-index: 1;
  height: 50px;
  border: none;
}

#experiment .ant-select-dropdown {
  width: unset !important;
  min-width: 200px !important;
}
/* 
#experiment .ant-select-selector {
  display: flex;
  align-items: center;
}
*/
#experiment .ant-select-selection-item[title="-Pilih Pattern-"] {
  height: unset !important;
}
#experiment
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-selection-item {
  height: 50px !important;
}

#experiment .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

/* .modal__experiment .ant-modal-content {
  background-color: var(--color--primary);
} */

.modal__experiment .ant-modal-close-x {
  color: white;
}

.modal__experiment .big-margin {
  margin-top: 0;
}

.watermark_image {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  width: 100px;
  z-index: 1;
}

.exp__social__btn {
  width: 50px;
  height: 50px;
  border-radius: 0px;
  margin: 5px;
  font-size: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(35, 34, 34);
  color: white;
}

.exp__copy__link {
  color: #d15a25;
}

/* .image__mobile {
  position: relative;
} */

.image__mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .select__pattern {
    bottom: 18.5rem;
  }

  .image__desktop {
    display: none;
  }

  .image__mobile {
    display: block;
    /* display: flex;
    flex-direction: column; */
    height: 100vh;
    /* justify-content: space-between; */
  }
}

.experiment_layout__mobile {
  display: none;
}

@media screen and (max-width: 500px) {
  .carousel__card {
    height: 100px;
    width: 90px;
  }

  .experiment_layout__mobile {
    height: 100%;
    z-index: -12;
    display: block;
  }

  .experiment_layout {
    height: calc(100% - 326px);
  }

  .experiment_layout.share {
    height: calc(100% - 90px);
  }

  .select__pattern {
    bottom: 12.8rem;
    height: 30px;
  }

  #experiment
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-select-selection-item {
    height: 30px !important;
  }

  .watermark_image {
    bottom: 1rem;
    width: 60px;
    right: 2.5rem;
  }
}

@media screen and (max-width: 376px) {
  #experiment .btn__primary,
  #experiment .btn__primary:focus {
    font-size: 10px;
  }

  .experiment__button {
    font-size: 10px;
  }

  .experiment__button svg {
    width: 25px;
    height: 25px;
  }

  .select__pattern {
    width: 180px;
    bottom: unset;
    top: 4rem;
  }

  .back__carousel {
    font-size: 12px !important;
  }

  .carousel__card {
    height: 80px;
    width: 90px;
  }

  .floor__image {
    height: 100%;
  }

  .title__card {
    padding: 5px 0;
  }
}

@media screen and (max-width: 330px) {
  .select__pattern {
    top: unset;
    bottom: 11.5rem;
  }
}

@media screen and (max-width: 425px) and (max-height: 900px) {
  .select__pattern {
    top: unset;
    bottom: 21rem;
    right: 10px;
  }
}

.smooth-image {
  transition: opacity 1s;
}
.image-visible {
  opacity: 1;
}
.image-hidden {
  opacity: 0;
}

.skele__not__ready {
  height: calc(100% - 229px);
}

.not__ready {
  display: flex;
  align-items: center;
  height: calc(100% - 0px);
}

.not__ready .ant-row {
  width: 100%;
}

.alice-carousel__wrapper {
  padding-left: 18px !important;
  padding-right: 18px;
}

.footer__mobile .btn__primary {
  background-color: white;
  border: 1px solid white;
  color: #212120;
}
