.bg-cover {
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bg-cover h1 {
  font-weight: 800;
  font-size: 54px;
  color: #ffffff;
}

.bg-cover img {
  width: 237px;
}

.btn__selanjutnya {
  background: #e84a24;
  color: white;
  min-width: 120px;
}

.btn__selanjutnya span {
  font-weight: 800;
  font-size: 20px;
}

a:hover {
  color: #0056b3;
  text-decoration: none;
}

@media (max-width: 767.98px) {
  .btn__selanjutnya {
    min-width: 120px;
  }

  .bg-cover h1 {
    font-size: 30px;
  }
}
.App {
  text-align: left;
}
/* styles.css */
.custom-logo-welcome {
  width: 400px !important;
  margin-top: 60px;
  margin-bottom: 35px; /* Default width for desktop */
  /* Add other desktop styles here */
}

@media (max-width: 768px) {
  .custom-logo-welcome {
    width: 300px !important;
    margin-top: 60px;
    margin-bottom: 35px; /* Adjust the height as needed */
    /* Add other mobile styles here */
  }
}
