* {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: auto;
}

.main {
  position: relative;
  width: 100%;

  color: #fff8de;
  overflow: hidden;
}

.main {
  height: 100vh;
}
@media screen and (max-width: 768px) {
}

canvas {
  width: 100%;
  height: 100%;
  /* position: absolute; */
  top: 0;
  /* overflow: hidden; */
}
