.back__component span.kembali {
  font-weight: 800 !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

@media only screen and (max-width: 768px) {
  .back__component span.kembali {
    font-size: 18px !important;
    line-height: 1;
  }
}

.cursor-pointer {
  cursor: pointer;
}
