@import "~antd/dist/antd.css";
@import "react-alice-carousel/lib/alice-carousel.css";

@font-face {
  font-family: "gothamblack";
  src: url("/public/ghotam/gothamblack-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothamblack-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambold";
  src: url("/public/ghotam/gothambold-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothambold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambold_italic";
  src: url("/public/ghotam/gothambolditalic-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothambolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook";
  src: url("/public/ghotam/gothambook-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothambook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook_italic";
  src: url("/public/ghotam/gothambookitalic-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothambookitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamlight";
  src: url("/public/ghotam/gothamlight-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothamlight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamlight_italic";
  src: url("/public/ghotam/gothamlightitalic-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothamlightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothammedium";
  src: url("/public/ghotam/gothammedium-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothammedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothammedium";
  src: url("/public/ghotam/gothammedium_1-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothammedium_1-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothammedium";
  src: url("/public/ghotam/gothammediumitalic-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothammediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App .backg-primary {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: white;
}

.padding__new_layout {
  padding-top: 2.35cm !important;
  padding-bottom: 2.35cm !important;
  padding-left: 2.35cm !important;
}

.pr-new {
  padding-right: 2.35cm;
}

@media only screen and (max-width: 768px) {
  .home__upload span {
    color: white;
  }
  .pr-new {
    padding-right: unset;
  }

  .padding__new_layout {
    padding-left: 1em !important;
    padding-right: 1em !important;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
  }
  .mobile_hide {
    display: none !important;
  }
}

.desktop_hide {
  display: none;
}

.h-100vh {
  height: 100vh !important;
}
.min-h-100vh {
  min-height: 100vh !important;
}

.font-bold {
  font-weight: bold;
  font-family: "gothambold";
}
.font-black {
  font-family: "gothamblack";
}
.font-ghotam {
  font-family: "gothambook", sans-serif !important;
}
.font-ghotam-black {
  font-family: "gothamblack", sans-serif !important;
}
.font-ghotam-book {
  font-family: "gothambook", sans-serif !important;
}

.height_calc__layout {
  height: calc(100% - 86px) !important;
}

.loading {
  position: relative;
  background-color: #dcdcdc;
  overflow: hidden;
}

.loading::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  animation: 2s loading linear 0.5s infinite;
  background: linear-gradient(90deg, transparent, #ededed, transparent);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  z-index: 1;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
