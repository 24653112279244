.ant-spin-dot-item {
  background-color: #d15a25;
  opacity: 1;
}

.cal__image__box {
  width: 100%;
  border-radius: 0px;
}

.cal__image__box.active {
  border: 5px solid #d15a25;
  border-radius: 0px;
}

.cal__image__cat {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 0px;
}

.btn__primary,
.btn__primary:focus {
  background-color: #d15a25;
  border: 1px solid #d15a25;
  color: white;
  font-weight: bold;
}

.btn__primary:hover {
  background-color: #d15925c0;
  border: 1px solid #d15925c0;
  color: white;
}

.cal__box__right {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0px;
  padding-top: 20px;
}

.cal__form__control {
  background: #1f1f1f;
  border: 4px solid #ffffff;
  box-sizing: border-box;
  border-radius: 0px;
  color: white;
  transition: all 200ms ease-in-out;
}

.cal__form__control .ant-select-selector {
  background-color: #1f1f1f !important;
  color: white;
}

.cal__form__control .ant-select-arrow {
  color: white;
}




.cal__form__control.ant-input:focus,
.cal__form__control.ant-input-focused,
.cal__form__control.ant-input:hover,
.cal__form__control:hover {
  border-color: #d15a25;
  border-right-width: unset !important;
  box-shadow: unset;
  transition: all 200ms ease-in-out;
  border-radius:0px!important;

}

.ant-select-single .ant-select-selector .ant-select-selection-search,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #d15a25 !important;
}

@media only screen and (max-width: 768px) {
  .cal__box__right {
    padding-top: 4px;
  }
  .big-padding {
    margin-bottom: 30px; 
  }
}
