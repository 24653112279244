body {
  overflow-x: hidden;
}
.container-fluid {
  padding-top: 1em;
  padding-left: 4em;
}

.container-fluid p {
  font-weight: 400;
  font-size: 16px;
  color: #2f2f2f;
}

.logo_nav {
  width: 100px;
}

.deskripsi h1 {
  font-weight: bold;
  font-size: 60px !important;
}
.deskripsi span {
  color: black !important;
  font-weight: normal !important;
  font-size: unset !important;
}
.deskripsi .deskripsi__new,
.deskripsi__new p,
.deskripsi__new span {
  font-size: 23px;
}

.home__upload span {
  color: white !important;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .deskripsi h1 {
    font-size: 32px !important;
  }
}

@media only screen and (max-width: 768px) {
  /* .deskripsi {
    margin-bottom: 20px;
  } */
  .deskripsi p {
    font-size: 14px !important;
  }
  .container-fluid {
    padding-left: 1em;
    padding-right: 1em;
  }
  .deskripsi h1 {
    font-size: 32px !important;
  }
}

.kategori__home__new {
  /* height: calc(100vh - 90px); */
  background-size: cover !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: white;
  padding-top: 1.8cm;
  padding-bottom: 1.8cm;
}
.kategori__home__new span {
  font-weight: 700;
  font-size: 24px;
  /* width: 184px; */
  color: white;
}

.kategori__home__new .type_kategori {
  font-weight: bold;
  font-size: 32px;
}

.btn__upload {
  background-color: #20201e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 386px; */
  min-height: 120px;
}

.btn__upload img {
  width: 40px;
}
.btn__upload .text {
  font-weight: 700;
  font-size: 12px;

  color: #e84a24;
}
.alice-carousel__stage-item {
  width: 317px !important;
}

.height_calc {
  /* height: calc(100% - 65px) !important; */
  height: calc(100% - 88px) !important;
}
.pr-new {
  padding-right: 2.35cm;
}

@media only screen and (max-width: 768px) {
  .col__category {
    /* padding-right: 1em !important;
    padding-left: 1em !important; */
    margin-top: 0px !important;
  }
  .pr-new {
    padding-right: unset;
  }
  .padding__new_layout {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
  .item__category {
    height: 185px;
    width: 100%;
  }
  .kategori__home__new span,
  .kategori__home__new .type_kategori {
    font-weight: 700;
    font-size: 22px;
    line-height: 150%;
  }
  .height_calc {
    height: unset !important;
  }
  .pr-4em {
    padding-right: unset;
  }
  .kategori__home__new {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .home__upload span {
    color: white !important;
    width: 70px;
  }

  .ant-select-selector {
    width: 48px!important;
    font-size: 9px!important;
    border-radius:0px!important;
  }
  .custom-select{
    border-radius:0px!important;
  }
}

.custom-select{
  border-radius:0px!important;
}

.ant-select-selector {
  border-radius:0px!important;
}

.mobile .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius:0px!important;
}

.ant-select-selector {
  border-radius:0px!important;
}

.ant-select-arrow {
  border-radius:0px!important;
}

.ant-select-selection-item{
  border-radius:0px!important;
}

/* styles.css */
.custom-logo-style {
  width: 250px; /* Default width for desktop */
  /* Add other desktop styles here */
}

@media (max-width: 768px) {
  .custom-logo-style {
    width: auto;
    height: 20px; /* Adjust the height as needed */
    /* Add other mobile styles here */
  }
}