body {
  margin: 0;
  /* font-family: "Inter", sans-serif !important;  */

  font-family: "gothambook", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

@font-face {
  font-family: "gothamblack";
  src: url("/public/ghotam/gothamblack-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothamblack-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambold";
  src: url("/public/ghotam/gothambold-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothambold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambold_italic";
  src: url("/public/ghotam/gothambolditalic-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothambolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook";
  src: url("/public/ghotam/gothambook-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothambook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook_italic";
  src: url("/public/ghotam/gothambookitalic-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothambookitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamlight";
  src: url("/public/ghotam/gothamlight-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothamlight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamlight_italic";
  src: url("/public/ghotam/gothamlightitalic-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothamlightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothammedium";
  src: url("/public/ghotam/gothammedium-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothammedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothammedium";
  src: url("/public/ghotam/gothammedium_1-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothammedium_1-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothammedium";
  src: url("/public/ghotam/gothammediumitalic-webfont.woff2") format("woff2"),
    url("/public/ghotam/gothammediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* code {
  font-family: "Inter", sans-serif !important;
} */

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1340px;
    max-width: 100%;
  }
}

/* CORE CSS YOKESEN */
:root {
  /* UI Colors */
  --color--primary: #212120;
  --color--secondary: #d15a25;
  --color--success: #ffffff;
  --color--info: #ffffff;
  --color--warning: #ffffff;
  --color--danger: #cf2020;
  --color--white: #ffffff;
  --color--black: black;
  /* ... */

  /* Box Shadows */
  --shadow-01: 0px 2px 4px rgba(37, 37, 37, 0.1);
  --shadow-02: 0px 4px 8px rgba(37, 37, 37, 0.1);
  --shadow-03: 0px 8px 16px rgba(37, 37, 37, 0.1);
  --shadow-04: 0px 16px 24px rgba(37, 37, 37, 0.1);
  --shadow-05: 0px 24px 32px rgba(37, 37, 37, 0.1);

  /* Border */
  --border--radius01: 5px;
  --border--radius02: 8px;
  --border--radius03: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.skeleton_loading {
  background: #eee !important;
  background: linear-gradient(
    110deg,
    #ececec 8%,
    #f5f5f5 18%,
    #ececec 33%
  ) !important;
  animation: shimmer 1.5s linear infinite;
  background-size: 200% 100% !important;
}

@keyframes shimmer {
  to {
    background-position-x: -200%;
  }
}

body {
  color: var(--color--primary);
}

h1 {
  font-size: 64px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

p {
  font-size: 14px;
}

a {
}

a:hover {
}

span {
}

button {
}

button:hover {
}

/* Alignment */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

/* Margin and Padding */
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 5px;
}
.mt-2 {
  margin-top: 10px;
}
.mt-3 {
  margin-top: 15px;
}
.mt-4 {
  margin-top: 20px;
}
.mt-5 {
  margin-top: 25px;
}
.mt-6 {
  margin-top: 30px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 5px;
}
.mb-2 {
  margin-bottom: 10px;
}
.mb-3 {
  margin-bottom: 15px;
}
.mb-4 {
  margin-bottom: 20px;
}
.mb-5 {
  margin-bottom: 25px;
}
.mb-6 {
  margin-bottom: 30px;
}
.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 5px;
}
.ml-2 {
  margin-left: 10px;
}
.ml-3 {
  margin-left: 15px;
}
.ml-4 {
  margin-left: 20px;
}
.ml-5 {
  margin-left: 25px;
}
.ml-6 {
  margin-left: 30px;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 5px;
}
.mr-2 {
  margin-right: 10px;
}
.mr-3 {
  margin-right: 15px;
}
.mr-4 {
  margin-right: 20px;
}
.mr-5 {
  margin-right: 25px;
}
.mr-6 {
  margin-right: 30px;
}
.pt-1 {
  padding-top: 5px;
}
.pt-2 {
  padding-top: 10px;
}
.pt-3 {
  padding-top: 15px;
}
.pt-4 {
  padding-top: 20px;
}
.pt-5 {
  padding-top: 25px;
}
.pt-6 {
  padding-top: 30px;
}
.pb-1 {
  padding-bottom: 5px;
}
.pb-2 {
  padding-bottom: 10px;
}
.pb-3 {
  padding-bottom: 15px;
}
.pb-4 {
  padding-bottom: 20px;
}
.pb-5 {
  padding-bottom: 25px;
}
.pb-6 {
  padding-bottom: 30px;
}
.pl-1 {
  padding-left: 5px;
}
.pl-2 {
  padding-left: 10px;
}
.pl-3 {
  padding-left: 15px;
}
.pl-4 {
  padding-left: 20px;
}
.pl-5 {
  padding-left: 25px;
}
.pl-6 {
  padding-left: 30px;
}
.pr-1 {
  padding-right: 5px;
}
.pr-2 {
  padding-right: 10px;
}
.pr-3 {
  padding-right: 15px;
}
.pr-4 {
  padding-right: 20px;
}
.pr-5 {
  padding-right: 25px;
}
.pr-6 {
  padding-right: 30px;
}
.px-1 {
  padding-right: 5px;
  padding-left: 5px;
}
.px-2 {
  padding-right: 10px;
  padding-left: 10px;
}
.px-3 {
  padding-right: 15px;
  padding-left: 15px;
}
.px-4 {
  padding-right: 20px;
  padding-left: 20px;
}
.px-5 {
  padding-right: 25px;
  padding-left: 25px;
}
.px-6 {
  padding-right: 30px;
  padding-left: 30px;
}
.py-1 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.py-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-3 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.py-4 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-5 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.py-6 {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* Font Size */
.fonts-72 {
  font-size: 72px;
  line-height: 120%;
}
.fonts-64 {
  font-size: 64px;
  line-height: 120%;
}
.fonts-48 {
  font-size: 48px;
  line-height: 120%;
}
.fonts-36 {
  font-size: 36px;
  line-height: 120%;
}
.fonts-32 {
  font-size: 32px;
  line-height: 120%;
}
.fonts-28 {
  font-size: 28px;
  line-height: 120%;
}
.fonts-24 {
  font-size: 24px;
  line-height: 120%;
}
.fonts-20 {
  font-size: 20px;
  line-height: 120%;
}
.fonts-18 {
  font-size: 18px;
  line-height: 120%;
}
.fonts-16 {
  font-size: 16px;
  line-height: 120%;
}
.fonts-14 {
  font-size: 14px;
  line-height: 120%;
}
.fonts-12 {
  font-size: 12px;
  line-height: 120%;
}

/* Font Weight */
.fontw-9 {
  font-weight: 900 !important;
}
.fontw-8 {
  font-weight: 800 !important;
}
.fontw-7 {
  font-weight: 700 !important;
}
.fontw-6 {
  font-weight: 600 !important;
}
.fontw-5 {
  font-weight: 500 !important;
}
.fontw-4 {
  font-weight: 400 !important;
}
.fontw-3 {
  font-weight: 300 !important;
}

/* Border */
.border-xs {
  border-radius: 0px !important;
}
.border-sm {
  border-radius: 0px !important;
}
.border-md {
  border-radius: 0px !important;
}
.border-lg {
  border-radius: 0px !important;
}

/* Opacity */
.opac-100 {
  opacity: 1;
}
.opac-70 {
  opacity: 0.7;
}
.opac-50 {
  opacity: 0.5;
}
.opac-30 {
  opacity: 0.3;
}

/* Font Color */
.fc-primary {
  color: var(--color--primary);
}
.fc-secondary {
  color: var(--color--secondary);
}
.fc-success {
  color: var(--color--success);
}
.fc-info {
  color: var(--color--info);
}
.fc-danger {
  color: var(--color--danger);
}
.fc-warning {
  color: var(--color--warning);
}
.fc-white {
  color: var(--color--white);
}
.fc-black {
  color: var(--color--black);
}

/* Background Color */
.backg-primary {
  background-color: var(--color--primary);
}
.backg-secondary {
  background-color: var(--color--secondary);
}
.backg-success {
  background-color: var(--color--success);
}
.backg-info {
  background-color: var(--color--info);
}
.backg-danger {
  background-color: var(--color--danger);
}
.backg-warning {
  background-color: var(--color--warning);
}
.backg-white {
  background-color: var(--color--white);
}
.backg-black {
  background-color: var(--color--black);
}

/* Box Shadow */
.sdw-xs {
  box-shadow: var(--shadow-01);
}
.sdw-sm {
  box-shadow: var(--shadow-02);
}
.sdw-md {
  box-shadow: var(--shadow-03);
}
.sdw-lg {
  box-shadow: var(--shadow-04);
}
.sdw-xl {
  box-shadow: var(--shadow-05);
}

.absolute {
  position: absolute !important;
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.w-full {
  width: 100%;
}
.h-10 {
  height: 10px;
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: start;
}
.justify-end {
  justify-content: end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: start;
}
.align-end {
  align-items: end;
}
.big-margin {
  margin-top: 4rem;
}

.big-padding {
  padding-top: 4rem;
}

.d-none {
  display: none;
}

@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 660px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  p {
    font-size: 13px;
  }
}
@media screen and (max-width: 500px) {
  .px-sm-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .d-sm-none {
    display: none;
  }

  .d-sm-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .big-margin {
    margin-top: 2rem;
  }

  .big-padding {
    padding-top: 2rem;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }

  p {
    font-size: 11px;
  }
}
@media screen and (max-width: 375px) {
}

.ant-message-custom-content {
  display: flex;
  align-items: center !important;
}
.ant-message-custom-content span {
  font-size: unset;
  font-weight: unset;
  color: unset;
  line-height: 0;
}

.ant-message-custom-content {
  padding: 7px 16px;
}

.no-border-radius {
  border-radius: 0px !important;
}
