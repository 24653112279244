.img_product {
  height: 160px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.cal__form__control {
  background: #fff;
  border: 2px solid #d7dadb;
  box-sizing: border-box;
  border-radius: 0px;
  color: black;
  transition: all 200ms ease-in-out;
  height: 35px;
}

.text-lable {
  font-weight: 500;
  font-size: 14px;
}

.btn__hitung {
  background: #e84a24;
  width: 100% !important;
  color: white;
  font-weight: 700;
  font-size: 16px;
}

.btn__hitung:hover {
  background: #c03f1e;
  color: white !important;
}

.btn__bagikan {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg__gradient {
  height: 300px;
  background-size: cover !important;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding-bottom: 40px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.tab {
  margin-left: 6px;
}

.layout__right {
  min-height: 100vh;
}

.custom_p {
  font-weight: 400;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .padding__new_layout {
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
  }
  .layout__right {
    min-height: unset;
  }
}


/* styles.css */
.custom-logo-style {
  width: 250px; /* Default width for desktop */
  /* Add other desktop styles here */
}

@media (max-width: 768px) {
  .custom-logo-style {
    width: auto;
    height: 30px; /* Adjust the height as needed */
    /* Add other mobile styles here */
  }
}
