.img__icon__plus {
  width: 30px;
}

.w-inherit {
  width: inherit;
}
@media (max-width: 991.98px) {
  .container-fluid {
    padding-right: unset;
  }
}
.container-fluid {
  padding-left: unset;
}

.border-lg {
  border-radius: 77px !important;
}

.card.card__category {
  background-color: grey;
  cursor: pointer;
  min-height: 54px;
}

.card.card__category.with__image {
  background-color: unset;
}

.kembali__product span.kembali {
  font-weight: 800 !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.card.card__category span {
  font-weight: 800;
  font-size: 54px;
  line-height: 1;
}
.card__category .categori {
  line-height: 1;
}

.card__footer {
  /* background-color: #4d4d4d; */
  font-weight: 700;
  font-size: 16px;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center; */
}

.img__product {
  height: 66px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.pilih__Kategori {
  /* font-weight: 800; */
  font-size: 36px;
  line-height: 1;
  padding-top: 13px;
}

.card__products {
  border-radius: 0px;
  border: 0px solid white;
  overflow: hidden;
  height: 150px;
}

.cursor__pointer {
  cursor: pointer !important;
}

.card__product {
  height: 66px;
  background-size: cover !important;
  background-position: center !important;
}

.card__products.active {
  border: 4px solid #d15a25;
}

.card__products .card__footer {
  /* background-color: #4d4d4d; */
  position: absolute;
  width: 100%;
  bottom: 0;
}

.image__rooom {
  position: relative;
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}

.ant-slider-track {
  height: 10px;
}

.ant-slider-rail {
  height: 10px;
}

.ant-slider-handle {
  width: 20px;
  height: 20px;
}

.nav-button-new {
  z-index: 10;
  height: auto;
  right: 28px;
  top: 16px;
}

.back__new {
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  top: 16px;
  left: 28px;
}

.collapseaa-media {
  cursor: pointer;
}

.taco__floor__share__product {
  height: 150px;
  width: 150px;
  border-radius: 0px;
  object-fit: cover;
}

.name__product {
  font-size: 12px;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.layout__left__new {
  margin-top: 20px;
  padding-left: 60px !important;
}

.card__product.active {
  border: 2px #e84a24 solid;
}
.header_cal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}

.header_cal__mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  font-weight: 400;
  font-size: 8px;
  width: 70px;
}
.calculate__mobile {
  background: #e84a24;
  text-align: center;
  color: white;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0rem;
}
.pattern__mobile {
  color: white;
}
.calculate {
  background: #e84a24;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.calculate.calculate__desktop {
  height: 58px;
  border-radius: 0rem;
}

.calculate:hover,
.btn__download:hover {
  /* background: gray; */
  background: rgb(168, 52, 23);
  color: white !important;
}

.btn__download {
  background: #e84a24;
  color: white;
  min-height: 84px;
  width: 100%;
  display: block;
}

.btn__download.btn__download__desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0rem;
}

.btn__download.btn__download__mobile {
  background: #e84a24;
  color: white;
  width: 100%;
  display: flex;
  height: 66px;
  align-items: center;
  min-height: unset;
  justify-content: center;
}

.select_experiment.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 2px grey solid;
  border-radius:0px!important;
}

.select_experiment.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-selection-item {
  height: 50px !important;
  overflow: hidden;
  border-radius:0px!important;
}

.bg__dark {
  background: grey;
  color: white;
  height: 50px !important;
}

@media only screen and (max-width: 768px) {
  .bg__dark {
    background: grey;
    color: white;
    height: 66px !important;
  }
}
.pattern__mobile .bg__dark {
  background: grey;
  color: white;
  height: 66px !important;
}

.pattern__mobile.select_experiment.ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.ant-select-selection-item {
  height: 66px !important;
  width: 100% !important;
  overflow: hidden;
  border-radius:0px!important;
}

.ant-image-img {
  object-fit: cover;
  object-position: center;
}

.select_experiment.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0;
  border-radius:0px!important;
}

.item__category__mobile {
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #494840;
  color: white;
  font-weight: 700;
  font-size: 16px;
}

.category__mobile {
  /* height: 340px; */
  overflow-y: scroll;
  margin-bottom: 0px;
  padding-right: 0.5rem;
}

.row__product__mobile {
  overflow-y: auto;
  /* margin-bottom: 10px; */
  /* padding-right: 0.5rem; */
}

.category__mobile::-webkit-scrollbar,
.row__product::-webkit-scrollbar,
.row__category__product::-webkit-scrollbar {
  width: 5px !important;
}

.category__mobile::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.category__mobile::-webkit-scrollbar-thumb,
.row__product::-webkit-scrollbar-thumb,
.row__category__product::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0rem !important;
  /* outline: 1px solid slategrey; */
  /* outline: 1px solid red; */
}

.product__left__scroll {
  overflow-y: auto;
  height: 434px;
  padding-right: 0.5rem;
}

.img__category {
  height: 54px;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.row__category__product {
  overflow-y: auto;
  height: calc(100vh - 335px);
  padding-right: 6px;
}

.row__product {
  overflow-y: auto;
  /* height: 190px; */
}

.select__product__item {
  padding-top: 11px;
}

.title__room__change {
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  bottom: 38px;
  font-size: 32px;
}

.kembali__mobile {
  font-weight: 800 !important;
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.4) !important;

  line-height: 1;
}

@media (max-width: 767.98px) {
  .pilih__Kategori {
    /* font-weight: 800; */
    font-size: 27px;
    padding-top: 0;
  }

  .ant-image-img {
    object-fit: unset;
    object-position: center;
  }
}

.padding__new_layout__mobile {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}


/* styles.css */
.custom-logo-style {
  width: 250px; /* Default width for desktop */
  /* Add other desktop styles here */
}

@media (max-width: 768px) {
  .custom-logo-style {
    width: auto;
    height: 30px; /* Adjust the height as needed */
    /* Add other mobile styles here */
  }
}
