small {
  font-size: 11px !important;
}

.msg__error {
  position: absolute;
  left: 10px;
  bottom: -21px;
}

.ant__input__mobile {
  width: 70%;
}

.msg__error__mobile {
  position: absolute;
  left: 2px;
  bottom: -18px;
}

/* .share__mobile {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 1em;
} */
.share__mobile .btn_mb_share{
    border-radius: 0rem;
    width: 70px;
}

.fw-500 {
  font-weight: 500;
}
.fs-14px {
  font-size: 14px;
}

.btn_mb_share {
  background-color: #20201e;
  color: white;
  font-weight: 400;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .kolom-mobile {
    margin-top: 24px;
  }}