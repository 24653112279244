body {
  overflow-x: hidden;
}
.container-fluid {
  padding-top: 1em;
  padding-left: 4em;
  padding-right: 4em;
}

.container-fluid p {
  font-weight: 400;
  font-size: 16px;
  color: #2f2f2f;
}

.logo_nav {
  width: 100px;
}

.deskripsi h1.title__category {
  font-weight: bold;
  font-size: 32px !important;
  padding-top: 13px;
}

.deskripsi p.text {
  margin-bottom: 1rem !important;
  margin-top: 0.5rem !important;
}

.kategori__home {
  height: calc(100vh - 98px);
  background-size: cover;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: white;
}
.kategori__home span {
  font-weight: 700;
  font-size: 24px;
  width: 184px;
}

.kategori__home .type_kategori {
  font-weight: 700;
  font-size: 24px;
}

.btn__upload {
  background-color: #20201e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 386px; */
  min-height: 120px;
}

.btn__upload img {
  width: 40px;
}
.btn__upload .text {
  font-weight: 700;
  font-size: 12px;

  color: #e84a24;
}
.alice-carousel__stage-item {
  width: 317px !important;
}

.carousel_slide {
  overflow-y: scroll;
  height: calc(100vh - 320px);
}
.col-kosong {
  height: 100px;
  /* border: 3px solid red; */
}
.h-100-vh {
  height: 100vh !important;
}

.carousel_slide::-webkit-scrollbar {
  width: 0px;
}

.carousel_slide::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0rem !important;
}
.top-0 {
  top: 0;
}

.name__room {
  /* font-weight: 700; */
  font-size: 20px;
  font-size: 32px;
  color: white;
}

.bg__gradient_cal {
  height: auto;
  background-size: cover !important;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding-bottom: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.bg__gradient__room {
  height: 100%;
  background-size: cover !important;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding-bottom: 20px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.bg-opacity {
  background-color: #000;
}

.row__carousel {
  position: absolute;
}

/* .col__list__room {
  z-index: 20;
} */

/* mobile view */
@media only screen and (max-width: 768px) {
  .row__carousel {
    position: relative;
    margin: unset !important;
    padding-right: 1em;
  }
  .col__list__room {
    padding-right: unset !important;
    margin-bottom: 15px !important;
  }
}


/* styles.css */
.custom-logo-style {
  width: 250px; /* Default width for desktop */
  /* Add other desktop styles here */
}

@media (max-width: 768px) {
  .custom-logo-style {
    width: auto;
    height: 30px; /* Adjust the height as needed */
    /* Add other mobile styles here */
  }
}

